import { v4 as uuidv4 } from 'uuid';

export class ChatWidget extends HTMLElement {
  private widgetId: string;
  private apiUrl: string = '/api/widget';
  private companyId: string = '';
  private sessionId: string = '';
  private useStreaming: boolean = true; // New flag to enable/disable streaming
  
  private chatContainer!: HTMLDivElement;
  private messagesContainer!: HTMLDivElement;
  private inputContainer!: HTMLDivElement;
  private chatButton!: HTMLButtonElement;
  private chatWindow!: HTMLDivElement;
  private chatInput!: HTMLTextAreaElement;
  private sendButton!: HTMLButtonElement;
  private isOpen: boolean = false;
  private isTyping: boolean = false;
  private typingIndicator!: HTMLDivElement;
  private isMobile: boolean = false;
  private isIPhone: boolean = false;
  private welcomeBanner!: HTMLDivElement;
  private bannerMessages: string[] = [
    'Try ScottAI - ask me anything!',
    'Got questions? I\'m here to help!',
    'Need assistance? Just ask!',
    'Let\'s chat - I\'m your AI assistant!'
  ];
  private currentBannerIndex: number = 0;
  private bannerRotationInterval: number | null = null;
  private readonly BANNER_ROTATION_DELAY = 5000; // 5 seconds
  private readonly BANNER_RESHOW_DELAY = 300000; // 5 minutes

  constructor() {
    super();
    this.widgetId = uuidv4();
    this.attachShadow({ mode: 'open' });
    this.checkMobileDevice();
    // Add resize listener for responsive updates
    window.addEventListener('resize', () => this.handleResize());
  }

  private checkMobileDevice(): void {
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    this.isIPhone = /iPhone/i.test(navigator.userAgent);
    
    console.log('Device detection:', { isMobile: this.isMobile, isIPhone: this.isIPhone });
    
    if (this.chatWindow) {
      this.updateLayoutForDevice();
    }
  }

  private handleResize(): void {
    const wasMobile = this.isMobile;
    this.checkMobileDevice();
    if (wasMobile !== this.isMobile) {
      this.updateLayoutForDevice();
    }
  }

  private updateLayoutForDevice(): void {
    if (this.isMobile) {
      // General mobile styles
      this.chatWindow.style.width = '100%';
      this.chatWindow.style.height = '100vh';
      this.chatWindow.style.bottom = '0';
      this.chatWindow.style.right = '0';
      this.chatWindow.style.borderRadius = '0';
      this.chatWindow.style.maxWidth = '100%';
      
      if (this.isIPhone) {
        // iPhone-specific adjustments - more restrictive
        this.chatWindow.style.width = 'calc(100% - 20px)';
        this.chatWindow.style.maxWidth = '400px'; // Set a hard max-width
        this.chatWindow.style.left = '50%'; // Center the window
        this.chatWindow.style.transform = 'translateX(-50%)'; // Center the window
        this.chatWindow.style.right = 'auto'; // Override the right setting
        
        // Add safe area insets for notches and home indicators
        this.chatWindow.style.paddingBottom = 'env(safe-area-inset-bottom, 10px)';
        this.chatWindow.style.paddingLeft = 'env(safe-area-inset-left, 10px)';
        this.chatWindow.style.paddingRight = 'env(safe-area-inset-right, 10px)';
      }
    } else {
      this.chatWindow.style.width = 'min(500px, 90vw)';
      this.chatWindow.style.height = 'min(600px, 70vh)';
      this.chatWindow.style.bottom = 'min(70px, 10vh)';
      this.chatWindow.style.right = '20px';
      this.chatWindow.style.borderRadius = '15px';
    }
  }

  async connectedCallback() {
    this.apiUrl = this.getAttribute('api-url') || '/api/widget';
    this.companyId = this.getAttribute('company-id') || '';
    
    // Check if streaming is disabled via attribute
    this.useStreaming = this.getAttribute('disable-streaming') !== 'true';
    
    if (!this.companyId) {
      console.error('ChatWidget: company-id attribute is required');
      return;
    }

    try {
      this.sessionId = this.getAttribute('session-id') || '';
      if (!this.sessionId) {
        await this.initializeSession();
      }
      
      this.render();
      this.setupEventListeners();
      this.loadChatHistory();
      this.setupKeyboardDetection();

      if (this.isIPhone) {
        // Force Safari to recalculate layout
        setTimeout(() => {
          this.chatWindow.style.display = 'none';
          setTimeout(() => {
            this.chatWindow.style.display = 'flex';
          }, 10);
        }, 100);
      }
    } catch (error) {
      console.error('Error initializing widget:', error);
      const errorEvent = new CustomEvent('error', { detail: error });
      this.dispatchEvent(errorEvent);
      throw error;
    }
  }

  private async initializeSession(): Promise<void> {
    const storedSessionId = localStorage.getItem('scott_chat_session_id');
    
    try {
      const response = await fetch(`${this.apiUrl}/session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          companyId: this.companyId,
          widgetId: this.widgetId,
          sessionId: storedSessionId || undefined
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to initialize session');
      }
      
      const data = await response.json();
      this.sessionId = data.sessionId;
      localStorage.setItem('scott_chat_session_id', this.sessionId);
    } catch (error) {
      console.error('Failed to initialize session:', error);
      throw error;
    }
  }

  private render() {
    if (!this.shadowRoot) return;

    // Create the chat container first
    this.chatContainer = document.createElement('div');
    this.chatContainer.className = 'chat-container';
    
    // Create the chat button wrapper and welcome banner
    const chatButtonWrapper = document.createElement('div');
    chatButtonWrapper.className = 'chat-button-wrapper';
    
    this.welcomeBanner = document.createElement('div');
    this.welcomeBanner.className = 'welcome-banner';
    this.welcomeBanner.style.display = 'none'; // Start hidden
    
    // Create dismiss button
    const dismissButton = document.createElement('button');
    dismissButton.className = 'dismiss-banner';
    dismissButton.innerHTML = '×';
    dismissButton.addEventListener('click', (e) => {
      e.stopPropagation();
      this.dismissBanner();
    });
    
    // Create banner content
    const bannerContent = document.createElement('span');
    bannerContent.textContent = this.bannerMessages[0];
    
    this.welcomeBanner.appendChild(bannerContent);
    this.welcomeBanner.appendChild(dismissButton);
    
    // Check if banner should be hidden
    const lastDismissed = localStorage.getItem('scott_chat_banner_dismissed');
    if (lastDismissed) {
      const dismissedTime = parseInt(lastDismissed);
      if (Date.now() - dismissedTime < this.BANNER_RESHOW_DELAY) {
        this.welcomeBanner.style.display = 'none';
      }
    }
    
    // Create the chat button
    this.chatButton = document.createElement('button');
    this.chatButton.className = 'chat-button';
    this.chatButton.style.opacity = '0';  // Start hidden
    this.chatButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>';
    
    // Check if this is first visit
    if (!localStorage.getItem('scott_chat_first_visit')) {
        // Show banner immediately if chat isn't open
        if (!this.isOpen) {
            this.welcomeBanner.style.display = 'flex';
            this.welcomeBanner.style.opacity = '1';
            this.startBannerRotation();
        }
        localStorage.setItem('scott_chat_first_visit', 'true');
    } else {
        this.chatButton.style.opacity = '1';
        if (!this.isOpen && !this.isBannerDismissed()) {
            this.welcomeBanner.style.display = 'flex';
            this.welcomeBanner.style.opacity = '1';
            this.startBannerRotation();
        }
    }
    
    chatButtonWrapper.appendChild(this.welcomeBanner);
    chatButtonWrapper.appendChild(this.chatButton);
    
    // Create the chat window
    this.chatWindow = document.createElement('div');
    this.chatWindow.className = 'chat-window';
    this.chatWindow.style.display = 'none';
    
    // Create the chat header
    const chatHeader = document.createElement('div');
    chatHeader.className = 'chat-header';
    
    const headerContent = document.createElement('div');
    headerContent.className = 'header-content';
    
    const headerTitle = document.createElement('div');
    headerTitle.className = 'header-title';
    headerTitle.textContent = 'Ask Scott AI';
    
    const headerSubtitle = document.createElement('div');
    headerSubtitle.className = 'header-subtitle';
    headerSubtitle.textContent = 'Ask me anything about our services!';
    
    headerContent.appendChild(headerTitle);
    headerContent.appendChild(headerSubtitle);
    
    const closeButton = document.createElement('button');
    closeButton.className = 'close-button';
    closeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>';
    closeButton.addEventListener('click', () => this.toggleChat());
    
    chatHeader.appendChild(headerContent);
    chatHeader.appendChild(closeButton);
    
    // Create the messages container with explicit containment
    this.messagesContainer = document.createElement('div');
    this.messagesContainer.className = 'messages-container';
    this.messagesContainer.style.width = '100%';
    this.messagesContainer.style.maxWidth = '100%';
    this.messagesContainer.style.boxSizing = 'border-box';
    
    // Create typing indicator
    this.typingIndicator = document.createElement('div');
    this.typingIndicator.className = 'typing-indicator';
    this.typingIndicator.innerHTML = '<span></span><span></span><span></span>';
    this.typingIndicator.style.display = 'none';
    this.messagesContainer.appendChild(this.typingIndicator);
    
    // Create the input container
    this.inputContainer = document.createElement('div');
    this.inputContainer.className = 'input-container';
    
    this.chatInput = document.createElement('textarea');
    this.chatInput.className = 'chat-input';
    this.chatInput.placeholder = 'Type your question here...';
    this.chatInput.rows = 1;
    
    this.sendButton = document.createElement('button');
    this.sendButton.className = 'send-button';
    this.sendButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#d75400" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>';
    
    this.inputContainer.appendChild(this.chatInput);
    this.inputContainer.appendChild(this.sendButton);
    
    // Assemble the chat window
    this.chatWindow.appendChild(chatHeader);
    this.chatWindow.appendChild(this.messagesContainer);
    this.chatWindow.appendChild(this.inputContainer);
    
    // Add styles first
    const style = document.createElement('style');
    style.textContent = this.getStyles();
    this.shadowRoot.appendChild(style);
    
    // Then append the container
    this.shadowRoot.appendChild(this.chatContainer);
    
    // Finally append components to container
    this.chatContainer.appendChild(chatButtonWrapper);
    this.chatContainer.appendChild(this.chatWindow);
    
    // Add welcome message
    this.addMessage('bot', 'Hello! How can I help you today?');
  }

  private setupEventListeners() {
    this.chatButton.addEventListener('click', () => this.toggleChat());
    
    this.sendButton.addEventListener('click', () => this.sendMessage());
    
    this.chatInput.addEventListener('keypress', (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        this.sendMessage();
      }
    });
    
    // Auto-resize textarea
    this.chatInput.addEventListener('input', () => {
      this.chatInput.style.height = 'auto';
      this.chatInput.style.height = `${Math.min(this.chatInput.scrollHeight, 100)}px`;
    });
  }

  private toggleChat() {
    this.isOpen = !this.isOpen;
    this.chatWindow.style.display = this.isOpen ? 'flex' : 'none';
    this.chatButton.style.backgroundColor = this.isOpen ? '#d75400' : '';
    this.chatButton.innerHTML = this.isOpen 
      ? '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>'
      : '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>';
    
    if (this.isOpen) {
      // Hide and stop banner when chat is opened
      this.welcomeBanner.style.display = 'none';
      this.stopBannerRotation();
      this.chatInput.focus();
      this.scrollToBottom();
    }
  }

  private async sendMessage() {
    const message = this.chatInput.value.trim();
    if (!message) return;
    
    this.addMessage('user', message);
    this.chatInput.value = '';
    this.chatInput.style.height = 'auto';
    this.showTypingIndicator();
    
    // Try streaming first if enabled and available
    if (this.useStreaming && typeof EventSource !== 'undefined') {
      try {
        await this.sendMessageStreaming(message);
        return;
      } catch (error) {
        console.log('Streaming failed, falling back to standard request:', error);
        // If streaming fails, we'll fall back to the standard request below
      }
    }
    
    try {
      const response = await fetch(`${this.apiUrl}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message,
          sessionId: this.sessionId,
          companyId: this.companyId,
          widgetId: this.widgetId
        }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to send message');
      }
      
      const data = await response.json();
      this.hideTypingIndicator();
      this.addMessage('bot', data.response);
      
    } catch (error) {
      console.error('Error sending message:', error);
      this.hideTypingIndicator();
      this.addMessage('bot', 'Sorry, I encountered an error. Please try again later.');
    }

    if (this.isMobile) {
      this.chatInput.blur(); // This will close the keyboard
    }
  }

  /**
   * Send a message using Server-Sent Events for streaming responses
   * @param message The user's message
   * @returns A promise that resolves when streaming is complete
   * @throws An error if streaming fails
   */
  private async sendMessageStreaming(message: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        // Create URL with query parameters
        const url = new URL(`${this.apiUrl}/stream/${this.sessionId}`);
        url.searchParams.append('message', message);
        url.searchParams.append('companyId', this.companyId);
        url.searchParams.append('widgetId', this.widgetId);
        
        // We will create the message element only after receiving the first chunk
        let messageElement: HTMLDivElement | null = null;
        let messageContent: HTMLDivElement | null = null;
        
        // Hide typing indicator as soon as we start receiving content
        let hasReceivedFirstChunk = false;
        const hideIndicatorOnFirstChunk = () => {
          if (!hasReceivedFirstChunk) {
            hasReceivedFirstChunk = true;
            this.hideTypingIndicator();
            
            // Create the bot message element only after receiving first chunk
            messageElement = document.createElement('div');
            messageElement.className = 'message bot-message';
            
            messageContent = document.createElement('div');
            messageContent.className = 'message-content';
            messageContent.textContent = '';
            
            messageElement.appendChild(messageContent);
            
            // Add the message to the container
            this.messagesContainer.insertBefore(messageElement, this.typingIndicator);
          }
        };
        
        // Connect to SSE endpoint
        const eventSource = new EventSource(url.toString());
        let fullResponse = '';
        let hasReceivedData = false;
        
        // Set a timeout to fall back to non-streaming if the connection takes too long
        const timeout = setTimeout(() => {
          if (!hasReceivedData) {
            console.log('Streaming connection timeout, falling back to standard request');
            eventSource.close();
            if (messageElement) {
              messageElement.remove();
            }
            reject(new Error('Streaming connection timeout'));
          }
        }, 10000); // 10 second timeout - modified to 10 seconds to accommodate orchestrator pre processing
        
        // Handle incoming messages
        eventSource.onmessage = (event) => {
          clearTimeout(timeout);
          hasReceivedData = true;
          
          // Hide typing indicator and create message element on first chunk
          hideIndicatorOnFirstChunk();
          
          // Check for event types used by our server
          if (event.data === '[DONE]') {
            // Streaming complete
            eventSource.close();
            
            // Format the final message and save to history if we have a message element
            if (messageContent) {
              messageContent.innerHTML = this.formatMessage(fullResponse);
              this.saveChatHistory('bot', fullResponse);
            }
            
            if (this.isMobile) {
              this.chatInput.blur(); // Close the keyboard
            }
            
            resolve();
            return;
          }
          
          try {
            // Try parsing as JSON first (for structured events)
            try {
              const jsonData = JSON.parse(event.data);
              // If this is a delta event from our server
              if (jsonData.type === 'content_block_delta' && jsonData.delta && jsonData.delta.text) {
                fullResponse += jsonData.delta.text;
              } else if (jsonData.content) {
                // Some other structured content
                fullResponse += jsonData.content;
              } else if (jsonData.text) {
                // Simple text content
                fullResponse += jsonData.text;
              }
            } catch (parseError) {
              // Not JSON, treat as plain text
              fullResponse += event.data;
            }
            
            // Format and update the message content if we have a message element
            if (messageContent) {
              messageContent.innerHTML = this.formatMessage(fullResponse);
              this.scrollToBottom();
            }
          } catch (error) {
            console.error('Error processing stream chunk:', error);
          }
        };
        
        // Handle errors
        eventSource.onerror = (error) => {
          console.error('SSE Error:', error);
          eventSource.close();
          clearTimeout(timeout);
          
          // If we've already received some data, we'll just use what we have
          if (hasReceivedData && messageContent) {
            this.hideTypingIndicator();
            messageContent.innerHTML = this.formatMessage(fullResponse);
            this.saveChatHistory('bot', fullResponse);
            resolve();
          } else {
            // If we haven't received any data, remove the placeholder and reject
            if (messageElement) {
              messageElement.remove();
            }
            reject(error);
          }
        };
      } catch (error) {
        console.error('Error setting up streaming:', error);
        reject(error);
      }
    });
  }

  private addMessage(sender: 'user' | 'bot', content: string) {
    // Create message wrapper
    const messageElement = document.createElement('div');
    messageElement.className = `message ${sender}-message`;
    
    // Create message content container
    const messageContent = document.createElement('div');
    messageContent.className = 'message-content';
    
    // Process markdown-like formatting
    const formattedContent = this.formatMessage(content);
    messageContent.innerHTML = formattedContent;
    
    // Clear existing content with the same text to prevent duplication
    const existingMessages = this.messagesContainer.querySelectorAll('.message-content');
    existingMessages.forEach(msg => {
        if (msg.innerHTML === formattedContent) {
            msg.parentElement?.remove();
        }
    });
    
    messageElement.appendChild(messageContent);
    
    // Insert before typing indicator
    this.messagesContainer.insertBefore(messageElement, this.typingIndicator);
    
    this.scrollToBottom();
    
    // Save to local storage
    this.saveChatHistory(sender, content);
  }

  private formatMessage(content: string): string {
    let formattedContent = content;
    
    // First clean up any existing HTML attributes that might be in the text
    formattedContent = formattedContent.replace(/\s+target="_blank"\s+rel="noopener noreferrer"/g, '');
    
    // Handle URLs with titles (e.g., from the LLM response)
    formattedContent = formattedContent.replace(
      /([^"'])(https?:\/\/[^\s]+)"\s+target="_blank"\s+rel="noopener\s+noreferrer">([^<]+)/g,
      '$1<a href="$2" target="_blank" rel="noopener noreferrer">$3</a>'
    );
    
    // Handle markdown-style links [text](url)
    formattedContent = formattedContent.replace(
      /\[([^\]]+)\]\(([^)]+)\)/g,
      '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
    );
    
    // Handle direct URLs that aren't already wrapped in HTML or markdown
    formattedContent = formattedContent.replace(
      /(?<![">])(https?:\/\/[^\s<]+)(?![^<]*<\/a>)/g,
      '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
    );
    
    // Handle bold text
    formattedContent = formattedContent.replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>');
    
    // Handle bullet points
    formattedContent = formattedContent.replace(/^\s*\*\s+(.+)$/gm, '<div class="bullet-point">$1</div>');
    
    // Convert line breaks
    formattedContent = formattedContent.replace(/\n/g, '<br>');
    
    return formattedContent;
  }

  private showTypingIndicator() {
    this.isTyping = true;
    this.typingIndicator.style.display = 'block';
    this.scrollToBottom();
  }

  private hideTypingIndicator() {
    this.isTyping = false;
    this.typingIndicator.style.display = 'none';
  }

  private scrollToBottom() {
    setTimeout(() => {
      this.messagesContainer.scrollTop = this.messagesContainer.scrollHeight;
    }, 0);
  }

  private saveChatHistory(sender: 'user' | 'bot', content: string) {
    try {
      const history = JSON.parse(localStorage.getItem(`scott_chat_history_${this.companyId}`) || '[]');
      history.push({ sender, content, timestamp: new Date().toISOString() });
      
      // Limit history to last 50 messages
      const limitedHistory = history.slice(-50);
      localStorage.setItem(`scott_chat_history_${this.companyId}`, JSON.stringify(limitedHistory));
    } catch (error) {
      console.error('Error saving chat history:', error);
    }
  }

  private loadChatHistory() {
    try {
      const history = JSON.parse(localStorage.getItem(`scott_chat_history_${this.companyId}`) || '[]');
      
      // Clear existing messages except welcome message
      while (this.messagesContainer.children.length > 1) {
        this.messagesContainer.removeChild(this.messagesContainer.firstChild as Node);
      }
      
      // Add messages from history
      history.forEach((item: { sender: 'user' | 'bot', content: string }) => {
        this.addMessage(item.sender, item.content);
      });
    } catch (error) {
      console.error('Error loading chat history:', error);
    }
  }

  private getStyles(): string {
    return `
      :host {
        --primary-color: #d75400;
        --secondary-color: #f8f9fa;
        --text-color: #212529;
        --border-color: #dee2e6;
        --shadow-color: rgba(0, 0, 0, 0.1);
        --bot-message-bg: #f1f0f0;
        --user-message-bg: #d75400;
        --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        
        /* Add these iPhone-specific variables */
        --iphone-chat-width: min(400px, calc(100% - 20px));
        --iphone-chat-height: 80vh;
      }

      .chat-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        max-width: min(500px, 95vw);
        position: fixed;
        box-sizing: border-box;
        bottom: 10px;
        right: 10px;
        z-index: 9999;
      }

      .chat-window {
        position: absolute;
        bottom: min(70px, 10vh);
        right: 20px;
        width: min(500px, 90vw);
        height: min(700px, 80vh);
        background-color: white;
        border-radius: 15px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: all 0.3s ease;
        box-sizing: border-box;
        max-width: 100%;
      }

      @media (max-width: 768px) {
        .chat-window {
          bottom: 0;
          right: 0;
          width: 100%;
          height: 60vh;
          border-radius: 15px 15px 0 0;
        }

        .chat-container {
          max-width: 100vw;
          right: 0px;
          bottom: 0px;
        }

        .chat-message {
          font-size: 14px;
        }
        
        .chat-input {
          font-size: 14px;
        }
      }

      @media screen and (max-width: 430px) {
        .chat-window {
          width: var(--iphone-chat-width) !important;
          max-width: var(--iphone-chat-width) !important;
          height: var(--iphone-chat-height) !important;
          left: 50% !important;
          right: auto !important;
          transform: translateX(-50%) !important;
          border-radius: 15px !important;
          position: fixed !important;
          margin: 0 !important;
          box-sizing: border-box !important;
        }
      }

      .chat-header {
        background-color: white;
        color: #212529;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid var(--border-color);
      }

      .header-content {
        padding-left: 16px;
      }

      .header-title {
        font-weight: 700;
        font-size: 18px;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .header-title::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #22c55e;
        border-radius: 50%;
      }

      .header-subtitle {
        font-size: 14px;
        color: #6b7280;
        margin-top: 4px;
      }

      .close-button {
        background-color: var(--primary-color);
        border: none;
        color: white;
        min-width: 44px;
        min-height: 44px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        transition: background-color 0.3s ease;
      }

      .close-button:hover {
        background-color: #c64e00;
      }

      .close-button svg {
        width: 16px;
        height: 16px;
        stroke: white;
      }

      .input-container {
        display: flex;
        padding: 10px;
        border-top: 1px solid var(--border-color);
        background-color: white;
        min-height: 50px;
        max-height: 100px;
        gap: 12px;
      }

      .chat-input {
        flex: 1;
        border: none;
        padding: 12px;
        font-size: 14px;
        resize: none;
        outline: none;
        font-family: var(--font-family);
        background-color: transparent;
      }

      .chat-input::placeholder {
        color: #9ca3af;
      }

      .send-button {
        background: none;
        border: none;
        min-width: 44px;
        min-height: 44px;
        padding: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.2s ease;
      }

      .send-button:hover {
        transform: scale(1.1);
      }

      .send-button svg {
        width: 20px;
        height: 20px;
      }

      .messages-container {
        flex: 1;
        overflow-y: auto;
        padding: 20px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        scroll-behavior: smooth;
        background-color: #ffffff;
        width: 100%;
        box-sizing: border-box;
        max-width: 100%;
        overflow-x: hidden;
      }

      .message {
        max-width: min(85%, 400px);
        padding: 12px 16px;
        border-radius: 12px;
        margin-bottom: 8px;
        word-wrap: break-word;
        line-height: 1.5;
        position: relative;
        font-size: 14px;
        box-sizing: border-box;
        width: fit-content;
      }

      .user-message {
        align-self: flex-end;
        background-color: var(--primary-color);
        color: white;
        border-bottom-right-radius: 4px;
      }

      .bot-message {
        align-self: flex-start;
        background-color: var(--bot-message-bg);
        border-bottom-left-radius: 4px;
      }

      .message-content {
        font-size: 14px;
        max-width: 100%;
        overflow-wrap: break-word;
        word-break: break-word;
      }

      .message-content a.message-link {
        color: #2563eb;
        text-decoration: none;
        position: relative;
        display: inline-block;
        margin-top: 8px;
        padding: 8px 16px;
        background-color: rgba(37, 99, 235, 0.1);
        border-radius: 8px;
        transition: background-color 0.2s ease;
        word-break: break-all;
      }

      .bot-message .message-content a.message-link {
        color: #2563eb;
      }

      .user-message .message-content a.message-link {
        color: white;
        background-color: rgba(255, 255, 255, 0.2);
      }

      .message-content a.message-link:hover {
        background-color: rgba(37, 99, 235, 0.15);
        text-decoration: none;
      }

      .user-message .message-content a.message-link:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }

      .typing-indicator {
        display: flex;
        align-items: center;
        background-color: var(--bot-message-bg);
        padding: 12px 16px;
        border-radius: 12px;
        border-bottom-left-radius: 4px;
        width: fit-content;
        margin-bottom: 8px;
      }

      .typing-indicator span {
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #666;
        border-radius: 50%;
        margin: 0 2px;
        animation: typing 1.4s infinite;
        animation-timing-function: ease-in-out;
      }

      .typing-indicator span:nth-child(2) {
        animation-delay: 0.2s;
      }

      .typing-indicator span:nth-child(3) {
        animation-delay: 0.4s;
      }

      @keyframes typing {
        0%, 100% {
          transform: translateY(0);
          opacity: 0.4;
        }
        50% {
          transform: translateY(-4px);
          opacity: 1;
        }
      }

      .chat-button {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: var(--primary-color);
        color: white;
        border: none;
        box-shadow: 0 4px 8px var(--shadow-color);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease, background-color 0.3s ease;
        opacity: 1;
        position: relative;
        z-index: 2;
      }

      .chat-button:hover {
        transform: scale(1.05);
      }

      .chat-button svg {
        width: 28px;
        height: 28px;
      }

      .bullet-point {
        position: relative;
        padding: 4px 0 4px 24px;
        margin: 0;
        line-height: 1.5;
      }

      .bullet-point::before {
        content: "•";
        position: absolute;
        left: 8px;
        top: 4px;
      }

      .message-content strong {
        font-weight: 600;
      }

      .chat-button-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 12px;
        margin-left: auto;
      }

      .welcome-banner {
        opacity: 0;
        background-color: white;
        color: var(--text-color);
        padding: 8px 16px;
        border-radius: 20px;
        box-shadow: 0 2px 8px var(--shadow-color);
        font-size: 14px;
        position: absolute;
        bottom: 100%;
        right: 50%;
        margin-bottom: 12px;
        white-space: nowrap;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: default;
        transition: opacity 0.3s ease;
        z-index: 1;
      }

      .welcome-banner span {
        transition: opacity 0.2s ease;
      }

      .dismiss-banner {
        background: none;
        border: none;
        color: #666;
        font-size: 18px;
        padding: 0;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        transition: background-color 0.2s ease;
      }

      .dismiss-banner:hover {
        background-color: rgba(0, 0, 0, 0.05);
        color: #333;
      }

      @keyframes bannerFadeOut {
        from { opacity: 1; transform: translateY(0); }
        to { opacity: 0; transform: translateY(-10px); }
      }

      @keyframes fadeIn {
        from { opacity: 0; transform: translateY(10px); }
        to { opacity: 1; transform: translateY(0); }
      }

      .chat-button {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: var(--primary-color);
        color: white;
        border: none;
        box-shadow: 0 4px 8px var(--shadow-color);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease, background-color 0.3s ease;
        opacity: 1;
        position: relative;
        z-index: 2;
      }

      @media (max-width: 768px) {
        .input-container {
          padding: 5px;
          min-height: 40px;
        }
        
        .chat-input {
          max-height: 80px;
        }
      }
    `;
  }

  private dismissBanner() {
    this.welcomeBanner.style.animation = 'bannerFadeOut 0.3s ease forwards';
    setTimeout(() => {
      this.welcomeBanner.style.display = 'none';
    }, 300);
    
    // Store dismissal time
    localStorage.setItem('scott_chat_banner_dismissed', Date.now().toString());
    
    // Stop banner rotation
    this.stopBannerRotation();
  }

  private startBannerRotation() {
    // Clear any existing interval
    if (this.bannerRotationInterval) {
      clearInterval(this.bannerRotationInterval);
    }
    
    // Start new rotation
    this.bannerRotationInterval = window.setInterval(() => {
      if (this.welcomeBanner.style.display !== 'none') {
        this.currentBannerIndex = (this.currentBannerIndex + 1) % this.bannerMessages.length;
        const bannerContent = this.welcomeBanner.querySelector('span');
        if (bannerContent) {
          bannerContent.style.opacity = '0';
          setTimeout(() => {
            bannerContent.textContent = this.bannerMessages[this.currentBannerIndex];
            bannerContent.style.opacity = '1';
          }, 200);
        }
      }
    }, this.BANNER_ROTATION_DELAY);
  }

  private stopBannerRotation() {
    if (this.bannerRotationInterval) {
      clearInterval(this.bannerRotationInterval);
      this.bannerRotationInterval = null;
    }
  }

  // Add cleanup in disconnectedCallback
  disconnectedCallback() {
    this.stopBannerRotation();
  }

  // Public methods
  public open() {
    if (!this.isOpen) {
      this.toggleChat();
    }
  }

  public close() {
    if (this.isOpen) {
      this.toggleChat();
    }
  }

  public clearHistory() {
    localStorage.removeItem(`scott_chat_history_${this.companyId}`);
    this.loadChatHistory();
  }

  private isBannerDismissed(): boolean {
    const lastDismissed = localStorage.getItem('scott_chat_banner_dismissed');
    if (lastDismissed) {
      const dismissedTime = parseInt(lastDismissed);
      return Date.now() - dismissedTime < this.BANNER_RESHOW_DELAY;
    }
    return false;
  }

  private setupKeyboardDetection(): void {
    if (this.isMobile) {
      // Original window height
      const originalWindowHeight = window.innerHeight;
      
      window.addEventListener('resize', () => {
        // If window height is significantly reduced, keyboard is likely open
        if (window.innerHeight < originalWindowHeight * 0.75) {
          console.log('Keyboard likely open', {
            originalHeight: originalWindowHeight,
            currentHeight: window.innerHeight
          });
          
          if (this.isIPhone) {
            // Adjust for iPhone keyboard while maintaining width constraints
            this.chatWindow.style.height = '40vh';
            this.chatWindow.style.maxHeight = '40vh';
            // Maintain width constraints
            this.chatWindow.style.width = 'calc(100% - 20px)';
            this.chatWindow.style.maxWidth = '400px';
            this.chatWindow.style.left = '50%';
            this.chatWindow.style.transform = 'translateX(-50%)';
            this.chatWindow.style.right = 'auto';
          } else {
            // Other mobile devices
            this.chatWindow.style.height = '50vh';
          }
          
          // Scroll to bottom to ensure visibility of input
          this.scrollToBottom();
        } else {
          console.log('Keyboard likely closed');
          
          if (this.isIPhone) {
            // Reset iPhone height while maintaining width constraints
            this.chatWindow.style.height = 'var(--iphone-chat-height)';
            this.chatWindow.style.maxHeight = 'var(--iphone-chat-height)';
            // Re-apply width constraints
            this.chatWindow.style.width = 'calc(100% - 20px)';
            this.chatWindow.style.maxWidth = '400px';
            this.chatWindow.style.left = '50%';
            this.chatWindow.style.transform = 'translateX(-50%)';
            this.chatWindow.style.right = 'auto';
          } else {
            // Reset other mobile devices
            this.chatWindow.style.height = '100vh';
          }
        }
      });
    }
  }
}

// Register the custom element
customElements.define('scott-chat-widget', ChatWidget); 