import { ChatWidget } from './components/ChatWidget';

// Register the custom element if not already registered
if (!customElements.get('scott-chat-widget')) {
  customElements.define('scott-chat-widget', ChatWidget);
}

// Auto-initialize if the script is loaded directly
if (typeof window !== 'undefined') {
  // Wait for the DOM to be fully loaded
  window.addEventListener('DOMContentLoaded', () => {
    // Check if there's an auto-init element
    const autoInitElements = document.querySelectorAll('[data-scott-chat-auto-init]');
    
    if (autoInitElements.length > 0) {
      autoInitElements.forEach(element => {
        // Get attributes from the element
        const companyId = element.getAttribute('data-company-id');
        const apiUrl = element.getAttribute('data-api-url');
        
        if (!companyId) {
          console.error('Scott Chat Widget: data-company-id attribute is required for auto-initialization');
          return;
        }
        
        // Create the widget element
        const widgetElement = document.createElement('scott-chat-widget');
        
        // Set attributes
        widgetElement.setAttribute('company-id', companyId);
        if (apiUrl) {
          widgetElement.setAttribute('api-url', apiUrl);
        }
        
        // Append to the body
        document.body.appendChild(widgetElement);
      });
    }
  });
}

// Add a global function to initialize the widget programmatically
if (typeof window !== 'undefined') {
  (window as any).ScottChatWidget = {
    init: (options: { 
      companyId: string; 
      apiUrl?: string; 
      container?: string | HTMLElement;
      sessionId?: string;
      debug?: boolean;
      disableStreaming?: boolean;
      onError?: (error: Error) => void;
    }) => {
      if (!options.companyId) {
        console.error('Scott Chat Widget: companyId is required');
        return null;
      }
      
      // Create the widget element
      const widgetElement = document.createElement('scott-chat-widget');
      
      // Set attributes
      widgetElement.setAttribute('company-id', options.companyId);
      if (options.apiUrl) {
        widgetElement.setAttribute('api-url', options.apiUrl);
      }
      if (options.sessionId) {
        widgetElement.setAttribute('session-id', options.sessionId);
      }
      if (options.debug) {
        widgetElement.setAttribute('debug', 'true');
      }
      if (options.disableStreaming) {
        widgetElement.setAttribute('disable-streaming', 'true');
      }
      
      // Append to the specified container or body
      let container: HTMLElement | null = document.body;
      
      if (options.container) {
        if (typeof options.container === 'string') {
          container = document.querySelector(options.container);
        } else {
          container = options.container;
        }
      }
      
      if (!container) {
        console.error('Scott Chat Widget: Container not found');
        return null;
      }
      
      container.appendChild(widgetElement);
      
      // Set up error handler if provided
      if (options.onError) {
        widgetElement.addEventListener('error', (event: any) => {
          options.onError?.(event.detail);
        });
      }
      
      return widgetElement;
    }
  };
} 